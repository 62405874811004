import { useEffect, useRef, useState } from "react";
import { Button } from "@mui/material";

import ui from "../../assets/css/ui.module.scss";
import styles from "../../assets/css/amount-changer.module.scss";
import { formatCurrency } from "../../libs/utils";
import FXRateLogo from "../../assets/img/FXRateLogo.svg";
import AppSkeleton from "../../skeletons/AppSkeleton";
import CrossIcon from "./CrossIcon";
import { baseColor, highlightColor } from "../../constants/skeletonColor";
import {
  INDIA_CURRENCY_FORMAT,
  DECIMAL_NUMBER_REGX,
  DEFAULT_REMITTANCE_AMOUNT,
  US_CURRENCY_FORMAT,
} from "../../constants/index";

function CurrencyConverter({
  fxRate,
  theme = "",
  promotional_exchange_rate_applicable = false,
  fromCalculatorPage = false,
  loader = false,
}) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const utmSource = urlParams.get("utm_source") || "abound";

  const [error, setError] = useState("");
  const [usdAmount, setUsdAmount] = useState();
  const [inrAmount, setInrAmount] = useState();
  const [inputDisabled, setInputDisabled] = useState(true);

  const usdAmountRef = useRef();
  const inrAmountRef = useRef();

  const usdValueRef = useRef();
  const inrValueRef = useRef();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    usdAmountRef?.current.blur();
    inrAmountRef?.current.blur();
  };

  const convertToNumber = (amount) => {
    return parseFloat(amount.toString().replace(/,/g, ""));
  };

  const handleAmountChange = (e, currencyType) => {
    const amount = e.target.value;

    if (currencyType === "USD") {
      if (convertToNumber(amount) > 1000000) {
        return;
      }
    }

    if (currencyType === "INR") {
      if (convertToNumber(amount) > 1000000 * fxRate) {
        return;
      }
    }

    setError("");
    if (!amount) {
      setUsdAmount("");
      setInrAmount("");
      usdValueRef.current = "";
      inrValueRef.current = "";
      return;
    }

    const convertedAmount = amount.toString().replace(/,/g, "");
    if (DECIMAL_NUMBER_REGX.test(convertedAmount)) {
      const parsedAmount = parseFloat(convertedAmount);
      let formattedAmount = "";
      if (!isNaN(parsedAmount)) {
        const inrAmount = parseFloat(
          currencyType === "INR"
            ? (parsedAmount / fxRate).toFixed(2)
            : (parsedAmount * fxRate).toFixed(2)
        );
        formattedAmount = formatCurrency(
          `${inrAmount.toLocaleString("fullwide", { useGrouping: false })}`,
          currencyType === "INR" ? US_CURRENCY_FORMAT : INDIA_CURRENCY_FORMAT,
          true
        );
        if (currencyType === "INR") {
          setUsdAmount(formattedAmount);
        } else {
          setInrAmount(formattedAmount);
        }
      }
      const formattedAmount2 = formatCurrency(
        convertedAmount,
        currencyType === "INR" ? INDIA_CURRENCY_FORMAT : US_CURRENCY_FORMAT
      );
      if (currencyType === "INR") {
        setInrAmount(formattedAmount2);
      } else {
        setUsdAmount(formattedAmount2);
      }
    }
  };

  const removeDecimal = (amount, formatType) => {
    const convertedAmount = parseFloat(amount?.toString().replace(/,/g, ""));
    const formattedAmount = formatCurrency(
      `${convertedAmount.toLocaleString("fullwide", { useGrouping: false })}`,
      formatType,
      true
    );
    return formattedAmount;
  };

  const handleClickOutside = (currencyType) => {
    const value = currencyType === "INR" ? inrAmount : usdAmount;
    if (value) {
      const temp = removeDecimal(
        currencyType === "INR" ? inrAmount : usdAmount,
        currencyType === "INR" ? INDIA_CURRENCY_FORMAT : US_CURRENCY_FORMAT
      );
      // const convertedAmount = inrAmount.toString().replace(/,/g, "");
      // const parsedAmount = parseFloat(convertedAmount);
      // const amount = parseFloat((parsedAmount / fxRate).toFixed(2));
      if (currencyType === "INR") {
        setInrAmount(temp);
      } else {
        setUsdAmount(temp);
      }
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      setInputDisabled(false);
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (fxRate) {
      const inrAmount = parseFloat(
        (DEFAULT_REMITTANCE_AMOUNT * fxRate).toFixed(2)
      );
      const formattedUsd = formatCurrency(
        `${DEFAULT_REMITTANCE_AMOUNT}`,
        US_CURRENCY_FORMAT,
        true
      );
      const formattedInr = formatCurrency(
        `${inrAmount}`,
        INDIA_CURRENCY_FORMAT,
        true
      );
      setInrAmount(formattedInr);
      setUsdAmount(formattedUsd);
    }
  }, [fxRate]);

  const handleContinue = async () => {
    if (fromCalculatorPage) {
      window.open(
        `${process.env.REACT_APP_TC_URL}/getapp/signup/v2?source=remittance&utm_source=${utmSource}`,
        "_blank"
      );
      return;
    }
  };

  return (
    <div>
      <div
        className={
          error
            ? `${
                theme === "LIGHT"
                  ? `${styles.usdAmountContainer} ${styles.usdAmountContainerLight}`
                  : styles.usdAmountContainer
              } ${styles.usdAmountContainerError}`
            : `${
                theme === "LIGHT"
                  ? `${styles.usdAmountContainer} ${styles.usdAmountContainerLight}`
                  : styles.usdAmountContainer
              }`
        }
      >
        <div className={styles.usdAmount}>
          <div className={styles.text}>You send</div>
          {!loader || usdAmount ? (
            <form onSubmit={handleFormSubmit}>
              <input
                disabled={inputDisabled}
                ref={usdAmountRef}
                type="text"
                onChange={(e) => handleAmountChange(e, "USD")}
                value={usdAmount}
                inputMode="decimal"
                className={styles.amountInput}
                onBlur={() => handleClickOutside("USD")}
              />
            </form>
          ) : (
            // <div className={styles.calculate}>Calculating...</div>
            <></>
          )}
        </div>
        <div className={styles.usdContainer}>
          <div className={styles.usdText}>USD</div>
          <div>
            <img
              src={
                "https://timesclub-static.s3.us-east-2.amazonaws.com/neobank_react/US.svg"
              }
              alt="USFlag"
              className={styles.usflag}
            />
          </div>
        </div>
      </div>
      {error && <div className={styles.amountError}>{error}</div>}
      <div className={styles.middleContainer}>
        <div className={styles.middleBox}>
          <div className={styles.leftSection}>
            <div
              className={
                theme === "LIGHT"
                  ? `${styles.vLine} ${styles.vLineLight}`
                  : styles.vLine
              }
            ></div>
            <div
              className={
                theme === "LIGHT"
                  ? `${styles.crossIcon} ${styles.crossIconLight}`
                  : styles.crossIcon
              }
            >
              <CrossIcon
                iconColor={theme === "LIGHT" ? "#9498B5" : "#FFFFFF"}
                height={16}
                width={16}
              />
            </div>

            <div
              className={
                theme === "LIGHT"
                  ? `${styles.vLine} ${styles.vLineLight}`
                  : styles.vLine
              }
            ></div>
          </div>

          {loader ? (
            <div className={`${styles.rightSection} ${styles.rightAligned}`}>
              <div
                className={
                  theme === "LIGHT"
                    ? `${styles.loader} ${styles.loaderLight}`
                    : styles.loader
                }
              ></div>
            </div>
          ) : (
            <div className={styles.rightSection}>
              <div className={styles.fxText}>
                {/* {formatExchangeRate(effective_exchange_rate)} */}
                {fxRate}
              </div>
              <div>
                <div
                  className={
                    theme === "LIGHT"
                      ? `${styles.exchangeCont} ${styles.exchangeContLight} `
                      : styles.exchangeCont
                  }
                >
                  <div className={styles.fxguaranteelogo}>
                    <img
                      alt="FXrate"
                      src={FXRateLogo}
                      className={styles.logoImage}
                    />
                  </div>
                  <div className={styles.exchangeTextContainer}></div>
                  <span className={styles.exchangeText}>
                    {promotional_exchange_rate_applicable
                      ? "Promotional Exchange Rate"
                      : "Best Exchange Rate"}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div
        className={`${
          theme === "LIGHT"
            ? `${styles.inrAmountContainer} ${styles.inrAmountContainerLight}`
            : styles.inrAmountContainer
        }`}
      >
        <div>
          <div className={styles.recipientText}>Recipient gets</div>
          {inrAmount ? (
            <form onSubmit={handleFormSubmit}>
              <input
                disabled={inputDisabled}
                ref={inrAmountRef}
                type="text"
                onChange={(e) => handleAmountChange(e, "INR")}
                value={inrAmount}
                inputMode="decimal"
                className={styles.amountInput}
                // pattern="^\d+(\.\d{0,2})?$"
                onBlur={() => handleClickOutside("INR")}
              />
            </form>
          ) : (
            <></>
          )}
        </div>

        <div className={styles.inrContainer}>
          <div className={styles.inrText}>INR</div>
          <div className={styles.Indiaflag}>
            <img
              src={
                "https://timesclub-static.s3.us-east-2.amazonaws.com/neobank_react/IN.svg"
              }
              alt="IndiaFlag"
            />
          </div>
        </div>
      </div>

      <div className={styles.bg}>
        <div className={styles.transferText}>
          Funds should arrive in 6-8 business days.
        </div>
        <div className={`${ui.footerBtnContainer} ${styles.btnContinue}`}>
          {loader ? (
            <div className={ui.btnSkeletonBox}>
              <AppSkeleton
                variant="rounded"
                height={56}
                baseColor={baseColor}
                highlightColor={highlightColor}
              />
            </div>
          ) : (
            <Button
              onClick={handleContinue}
              className={`${ui.btnGolden} ${styles.btnContinue}`}
            >
              Get Started
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CurrencyConverter;
