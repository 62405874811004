const formatCurrency = (number, formatType, formatDecimal = false) => {
  const hasDecimal = number.indexOf(".") > -1;
  if (!hasDecimal) {
    return new Intl.NumberFormat(formatType).format(number);
  }
  const [integerPortion, decimalPortion] = number.split(".");
  const formattedIntegerPortion = new Intl.NumberFormat(formatType).format(
    integerPortion
  );
  if (formatDecimal) {
    const decimal =
      decimalPortion?.length === 1 ? `${decimalPortion}0` : decimalPortion;
    return `${formattedIntegerPortion}.${decimal}`;
  }

  return `${formattedIntegerPortion}.${decimalPortion}`;
};

export { formatCurrency };
