import { useEffect, useState } from "react";

import styles from "../../assets/css/remittance.compare.module.scss";
import FXRateLogo from "../../assets/img/FXRateLogo.svg";
import arrowdown from "../../assets/img/arrowdown.svg";
import learnMore from "../../assets/img/learnMore.png";

import { baseColor, highlightColor } from "../../constants/skeletonColor";
import {
  fetchCompareRates,
  fetchExchangeRate,
} from "../../services/remittance";
import AppSkeleton from "../../skeletons/AppSkeleton";
import { formatCurrency } from "../../libs/utils";
import { useSearchParams } from "react-router-dom";

const CompareRate = () => {
  const [fxRateData, setFxRateData] = useState({});
  const [compareRateData, setCompareRateData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchParams] = useSearchParams();
  const effective_exchange_rate = searchParams.get("effective_exchange_rate");
  const theme = searchParams.get("theme");
  const deviceType = searchParams.get("deviceType");

  const learnMoreUrl = "https://www.joinabound.com/faq";

  const usdAmount = 1000;

  const {
    promo_fx_rate,
    base_fx_rate,
    promotional_exchange_rate_applicable
    // promo_condition,
  } = fxRateData || {};
  // const promo_fx_rate = 83.29;
  // const base_fx_rate = 82.88;
  const effective_exchange_amount = effective_exchange_rate
    ? effective_exchange_rate * usdAmount
    : 0;
  const fxAmount = usdAmount * base_fx_rate;
  const promoAmount = usdAmount * promo_fx_rate;

  const getExchangeRate = async () => {
    try {
      setLoader(true);
      if (!effective_exchange_rate) {
        const exchangeRate = await fetchExchangeRate();
        if (exchangeRate?.success) {
          setFxRateData({
            ...exchangeRate,
            promotional_exchange_rate_applicable: true,
          });
          getCompareRates();
          setLoader(false);
        }
        return;
      }
      getCompareRates();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const getCompareRates = async () => {
    try {
      setLoader(true);
      const compareRateData = await fetchCompareRates();
      if (compareRateData?.success) {
        setCompareRateData(compareRateData.response);
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  useEffect(() => {
    getExchangeRate();
  }, []);

  const removeDecimal = (amount, formatType) => {
    const convertedAmount = parseFloat(amount?.toString().replace(/,/g, ""));
    const formattedAmount = formatCurrency(
      `${convertedAmount.toLocaleString("fullwide", { useGrouping: false })}`,
      formatType,
      true
    );
    return formattedAmount;
  };
  const sendMessage = (data = {}) => {
    window.parent.postMessage(
      JSON.stringify(data),
      process.env.REACT_APP_NEOBANK_URL || process.env.REACT_APP_NEOBANK_UAT_URL
    );
  };
  const handleLearnMore = () => {
    sendMessage({ action: "LEARN_MORE", url: learnMoreUrl });
  };

  const handleData = (transfer_fee, fxRate) => {
    let amount = "";

    if (effective_exchange_rate) {
      amount = (
        (usdAmount - transfer_fee) * fxRate -
        effective_exchange_amount
      ).toFixed(2);
    } else if (promotional_exchange_rate_applicable) {
      amount = ((usdAmount - transfer_fee) * fxRate - promoAmount).toFixed(2);
    } else {
      amount = ((usdAmount - transfer_fee) * fxRate - fxAmount).toFixed(2);
    }
    if (amount < 0) {
      const formattedAmount = amount * -1;
      return (
        <>
          <span className={styles.difference}>
            {`-₹${removeDecimal(formattedAmount)}`}
          </span>
          <img src={arrowdown} alt="arrowdown" className={styles.logo} />
        </>
      );
    }
  };
  return (
    <>
      <div
        className={
          theme === "LIGHT" ? styles.exchangeContLight : styles.container
        }
      >
        {deviceType === "WEB" && (
          <div className={styles.headerText}>Compare FX Rates</div>
        )}
        <div className={styles.description}>
          <span className={styles.descriptionText}>
            With the best exchange rates in the market, Abound offers the most
            affordable way of sending money to India.
          </span>
        </div>
        <div>
          <div className={styles.cardfxContainer}>
            <div className={styles.cardItemNormal}>
              <div className={styles.mainContainer}>
                <div className={styles.fxRateBox}>
                  <div className={styles.fxRateContainer}>
                    <div className={styles.fxRateheader}>
                      <span className={styles.fxRateText}>Our Rate</span>
                    </div>

                    <div className={styles.discountedContainer}>
                      <span className={styles.fxAmount}>$1000 = </span>

                      <span
                        className={`${styles.fxAmount} ${styles.fxAmountMargin}`}
                      >
                        {/* ₹{formatExchangeRate(effective_exchange_rate)} */}
                        {loader ? (
                          <AppSkeleton
                            height={24}
                            width={36}
                            baseColor={baseColor}
                            highlightColor={highlightColor}
                          />
                        ) : effective_exchange_amount ? (
                          `₹${removeDecimal(effective_exchange_amount)}`
                        ) : promotional_exchange_rate_applicable ? (
                          promoAmount && `₹${removeDecimal(promoAmount)}`
                        ) : (
                          fxAmount && `₹${removeDecimal(fxAmount)}`
                        )}
                      </span>
                    </div>
                  </div>

                  <div className={styles.fxguaranteelogo}>
                    <img
                      alt="FXrate"
                      src={FXRateLogo}
                      className={styles.logoImg}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div>
                    <span className={styles.rateText}>
                      Enjoy our unbeatable exchange rate of{" "}
                    </span>
                    <span className={styles.exchangeRate}> $1 to </span>
                    <span className={styles.exchangeRate}>
                      ₹
                      {effective_exchange_rate
                        ? effective_exchange_rate
                        : promotional_exchange_rate_applicable
                        ? promo_fx_rate
                        : base_fx_rate}
                    </span>
                    <span className={styles.rateText}>
                      {" "}
                      when you send money with Abound
                    </span>
                  </div>
                  <div className={styles.btnMore}>
                    {!effective_exchange_rate ? (
                      <a href={learnMoreUrl} target="_blank">
                        <img
                          src={learnMore}
                          alt="learn More"
                          className={styles.learnImg}
                        />
                      </a>
                    ) : (
                      <span onClick={() => handleLearnMore()}>
                        <img
                          src={learnMore}
                          alt="learn More"
                          className={styles.learnImg}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.compareContainer}>
          <span className={styles.compareText}>How others compare</span>
          <div className={styles.ratedataContainer}>
            {theme === "LIGHT" ? (
              <>
                <span className={styles.sendText}>
                  You send ${usdAmount} with
                </span>
                <span className={styles.sendText}>Recipient gets</span>
              </>
            ) : (
              <>
                <span className={styles.sendText}>You send with</span>
                <span className={styles.sendText}>You get</span>
              </>
            )}
          </div>
          <span className={styles.borderLine}></span>
          <div>
            {loader ? (
              <>
                <span>
                  <AppSkeleton
                    height={54}
                    width="100%"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                    className={styles.fxAmountMargin}
                  />
                  <AppSkeleton
                    height={54}
                    width="100%"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                    className={styles.fxAmountMargin}
                  />
                  <AppSkeleton
                    height={54}
                    width="100%"
                    baseColor={baseColor}
                    highlightColor={highlightColor}
                    className={styles.fxAmountMargin}
                  />
                </span>
              </>
            ) : (
              <>
                {compareRateData?.map((data) => {
                  return (
                    <>
                      <div className={styles.dataContainer}>
                        <div className={styles.logoContent}>
                          <img
                            className={
                              data.provider_name !== "Xe"
                                ? `${styles.partnerLogo}`
                                : `${styles.xeLogo}`
                            }
                            src={data.logo}
                          />
                          {data.provider_name === "Xe" && (
                            <span className={styles.moneyTransfer}>
                              money transfer
                            </span>
                          )}
                        </div>

                        <div className={styles.rateContainer}>
                          <div>
                            <span className={styles.fee}>
                              {data.fx_rate
                                ? `₹${removeDecimal(
                                    (
                                      (usdAmount - data.transfer_fee) *
                                      data.fx_rate
                                    ).toFixed(2)
                                  )}`
                                : ""}
                            </span>

                            {data.transfer_fee > 0 && (
                              <span className={styles.feeText}>
                                {" "}
                                (incl Fee: ${data.transfer_fee})
                              </span>
                            )}
                          </div>

                          <div className={styles.diffContainer}>
                            {handleData(data.transfer_fee, data.fx_rate)}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompareRate;
