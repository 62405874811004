import { Skeleton } from '@mui/material';

function AppSkeleton({
  height,
  width,
  baseColor,
  highlightColor,
  variant = "text",
  className,
}) {
  return (
    <Skeleton
      className={className}
      height={height}
      width={width}
      animation="wave"
      variant={variant}
      sx={
        baseColor
          ? {
              bgcolor: baseColor,
              "&::after": {
                background: `linear-gradient( 90deg, ${baseColor} , ${highlightColor},${baseColor} )`,
              },
            }
          : {}
      }
    />
  );
}

export default AppSkeleton;
