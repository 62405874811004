const CrossIcon = ({
    width = 16,
    height = 16,
    backgroundColor = "none",
    iconColor = "#ffffff",
  }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 16 17"
        fill={backgroundColor}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="close">
          <path
            id="Vector"
            d="M9.05997 8.0425L12.0287 5.07375C12.1696 4.9331 12.2489 4.74224 12.249 4.54316C12.2492 4.34408 12.1703 4.15308 12.0297 4.01219C11.889 3.87129 11.6982 3.79204 11.4991 3.79186C11.3 3.79169 11.109 3.8706 10.9681 4.01125L7.99935 6.98L5.0306 4.01125C4.8897 3.87035 4.69861 3.7912 4.49935 3.7912C4.30009 3.7912 4.10899 3.87035 3.9681 4.01125C3.8272 4.15215 3.74805 4.34324 3.74805 4.5425C3.74805 4.74176 3.8272 4.93285 3.9681 5.07375L6.93685 8.0425L3.9681 11.0112C3.8272 11.1521 3.74805 11.3432 3.74805 11.5425C3.74805 11.7418 3.8272 11.9329 3.9681 12.0737C4.10899 12.2146 4.30009 12.2938 4.49935 12.2938C4.69861 12.2938 4.8897 12.2146 5.0306 12.0737L7.99935 9.105L10.9681 12.0737C11.109 12.2146 11.3001 12.2938 11.4993 12.2938C11.6986 12.2938 11.8897 12.2146 12.0306 12.0737C12.1715 11.9329 12.2506 11.7418 12.2506 11.5425C12.2506 11.3432 12.1715 11.1521 12.0306 11.0112L9.05997 8.0425Z"
            fill={iconColor}
          />
        </g>
      </svg>
    );
  };
  
  export default CrossIcon;
  