import { useEffect, useState } from "react";

import styles from "../../assets/css/remittance-home.module.scss";
import FXRateLogo from "../../assets/img/FXRateLogo.svg";
import boosterLogo from "../../assets/img/boosterLogo.svg";

import { baseColor, highlightColor } from "../../constants/skeletonColor";
import { fetchExchangeRate } from "../../services/remittance";
import AppSkeleton from "../../skeletons/AppSkeleton";
import CurrencyConverter from "./CurrencyConverter";

const RemittanceCalculator = () => {
  const [fxRateData, setFxRateData] = useState({});
  const [loader, setLoader] = useState(false);

  const {
    formatted_base_fx_rate,
    formatted_promo_fx_rate,
    promo_fx_rate,
    base_fx_rate,
    promo_condition,
    promotional_exchange_rate_applicable,
  } = fxRateData || {};

  const getExchangeRate = async () => {
    try {
      setLoader(true);
      const exchangeRate = await fetchExchangeRate();
      if (exchangeRate?.success) {
        setFxRateData({
          ...exchangeRate,
          promotional_exchange_rate_applicable: true,
        });
        setLoader(false);
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  useEffect(() => {
    getExchangeRate();
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headerText}>Send Money to India</div>
        <div className={styles.swiperContainer}>
          {promotional_exchange_rate_applicable === true ? (
            <div className={styles.cardContainer}>
              <div className={styles.cardfxContainer}>
                <div className={styles.cardItemsPromotional}>
                  <div className={styles.promoBox}>
                    <div className={styles.fxguaranteelogo}>
                      <img
                        alt="FXrate"
                        src={boosterLogo}
                        className={styles.promotionallogoImg}
                      />
                    </div>
                    <div className={styles.fxRateContainer}>
                      <div className={styles.fxRateheader}>
                        <span className={styles.fxRateText}>
                          Promotional Exchange Rate
                        </span>
                      </div>
                      <div className={styles.discountedContainer}>
                        <span className={styles.fxAmount}>$1 = </span>
                        {loader ? (
                          <span>
                            <AppSkeleton
                              height={24}
                              width={72}
                              baseColor={baseColor}
                              highlightColor={highlightColor}
                              className={styles.fxAmountMargin}
                            />
                          </span>
                        ) : (
                          <>
                            <span className={styles.discountedfx}>
                              {/* ₹{formatExchangeRate(current_exchange_rate)} */}
                              {`${formatted_base_fx_rate}`}
                            </span>
                            <span className={styles.fxAmount}>
                              {/* ₹{formatExchangeRate(effective_exchange_rate)} */}
                              {`${formatted_promo_fx_rate}`}
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className={styles.btnContainer}>
                      <div className={styles.compareCont}>
                        <span className={styles.compare}>Best Rate</span>
                      </div>
                    </div>
                  </div>
                  <div className={styles.validTxnContainer}>
                    <span className={styles.validTxn}>{promo_condition}</span>
                  </div>
                </div>

                <div className={styles.zeroFeeContainer}>
                  <div className={styles.zeroFeeText}>
                    Zero fees, no suprises.
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.cardContainer}>
              <div className={styles.cardfxContainer}>
                <div className={styles.cardItemNormal}>
                  <div className={styles.fxguaranteelogo}>
                    <img
                      alt="FXrate"
                      src={FXRateLogo}
                      className={styles.logoImg}
                    />
                  </div>
                  <div className={styles.fxRateContainer}>
                    <div className={styles.fxRateheader}>
                      <span className={styles.fxRateText}>
                        Best Exchange Rate. Always!
                      </span>
                    </div>

                    <div className={styles.discountedContainer}>
                      <span className={styles.fxAmount}>$1 = </span>

                      <span
                        className={`${styles.fxAmount} ${styles.fxAmountMargin}`}
                      >
                        {/* ₹{formatExchangeRate(effective_exchange_rate)} */}
                        {loader ? (
                          <AppSkeleton
                            height={24}
                            width={36}
                            baseColor={baseColor}
                            highlightColor={highlightColor}
                          />
                        ) : (
                          `${formatted_base_fx_rate}`
                        )}
                      </span>
                    </div>
                  </div>
                  <div className={styles.btnContainer}>
                    <div className={styles.compareCont}>
                      <span className={styles.compare}>Best Rate</span>
                    </div>
                  </div>
                </div>

                <div className={styles.zeroFeeContainer}>
                  <div className={styles.zeroFeeText}>
                    Zero fees, no suprises.
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <CurrencyConverter
          fxRate={
            promotional_exchange_rate_applicable ? promo_fx_rate : base_fx_rate
          }
          fromCalculatorPage={true}
          loader={loader}
        />
      </div>
    </>
  );
};

export default RemittanceCalculator;
