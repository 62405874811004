import CompareRate from "./components/RateComparison/CompareRate";
import RemittanceCalculator from "./components/RemittanceWidget/RemittanceCalculator";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RemittanceCalculator />} />
        <Route path="/rate-comparison" element={<CompareRate />} />
      </Routes>
    </Router>
  );
}

export default App;
