import axios from "axios";
import appConfig from "../config/app.config";

const fetchExchangeRate = () => {
  return new Promise((resolve, reject) => {
    const config = {
      url: `${appConfig.apiUrl.exchangeRate}?source_currency=USD&destination_currency=INR`,
    };
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchCompareRates = () => {
  return new Promise((resolve, reject) => {
    const config = {
      url: `${appConfig.apiUrl.compareRate}`,
    };
    axios(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { fetchExchangeRate,fetchCompareRates };
